// @flow
import React from 'react';
import { ToolbarDelimitr, Flex } from '@graphite/uneon';
import { Design as SelectDesign } from '@graphite/selects';
import { defaultDesign } from '@graphite/constants';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { genId } from 'libs/firebase';
import { updateSpecs } from 'Editor/ducks/specs';
import { editWidget } from 'Editor/ducks/widgets';
import WidgetSettings from '@graphite/widget-settings';
import { AlignSelfToolbar } from '@graphite/align-self';
import { closestDeviceWithKey } from '@graphite/selectors';
import Settings from 'Widget/Settings';
import type { TWidgetDiff, TWidgetBoxBreakpoint } from '@graphite/types';
import DesignPanel from './Design/Panel';
import DesignColor from './Design/Color';
import DesignStyle from './Design/Style';
import Direction from './Direction';

import type { TConnectPropsWithControls } from '../constants/types';
import JustifyContent from './JustifyContent';
import AlignItems from './AlignItems';

const FlexDirection = styled(Flex)`
	${({ isRevers }) => (isRevers ? 'flex-direction: row-reverse;' : '')}
`;
const Controls = ({
	currentDevice,
	colorspec,
	gridspec,
	effectspec,
	widgetspec,
	dispatch,
	data,
	instanceId,
	originId,
	position,
	currentRef,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
	direction,
	alignItems,
}: TConnectPropsWithControls) => {
	const { t } = useTranslation();
	const { _id, designId, designs } = data;
	const [openedPanel, setOpenedPanel] = React.useState(null);

	const saveDiff = React.useCallback(
		(diff: TWidgetDiff) => {
			if (!originId) return;
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, instanceId, originId, dispatch],
	);

	const customDesign = (designs && designId && designs[designId]) || null;

	const saveDesign = React.useCallback(
		(designId, design) => {
			if (!designId && !design) {
				return;
			}
			if (!originId) return;
			const diff = {};
			if (designId) {
				diff.designId = designId;
			}
			if (design) {
				diff.designs = {
					...designs,
					[design._id]: design,
				};
			}
			dispatch(editWidget(_id, instanceId, originId, diff));
		},
		[_id, designs, dispatch, instanceId, originId],
	);

	const defDesign = React.useMemo(
		() =>
			customDesign ||
			(designId && widgetspec.stack.find(b => b._id === designId)) ||
			defaultDesign,
		[customDesign, designId, widgetspec.stack],
	);

	const updateSpecsHandler = React.useCallback(
		specs => {
			dispatch(updateSpecs(specs));
		},
		[dispatch],
	);
	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice,
		key: `box-${data._id}`,
	});

	return (
		<>
			<SelectDesign
				target="stack"
				designId={designId}
				device={currentDevice}
				customDesign={customDesign}
				defaultDesign={defDesign}
				DesignPanel={DesignPanel}
				DesignColor={DesignColor}
				DesignStyle={DesignStyle}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				onChange={saveDesign}
				t={t}
				genCustomId={genId}
				updateSpecs={updateSpecsHandler}
				insertImage={insertImage}
				resetImage={resetImage}
				removeImage={removeImage}
				images={images}
				uploads={uploads}
			/>

			<ToolbarDelimitr />
			<Direction
				save={saveDiff}
				data={data}
				device={currentDevice}
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
			/>
			<FlexDirection isRevers={box.flexDirection !== 'row'}>
				<JustifyContent
					save={saveDiff}
					data={data}
					device={currentDevice}
					openedPanel={openedPanel}
					setOpenedPanel={setOpenedPanel}
				/>
				<AlignItems
					save={saveDiff}
					data={data}
					device={currentDevice}
					openedPanel={openedPanel}
					setOpenedPanel={setOpenedPanel}
				/>
			</FlexDirection>
			<ToolbarDelimitr />

			<AlignSelfToolbar
				t={t}
				onChange={saveDiff}
				direction={direction}
				data={data}
				alignItems={alignItems}
				currentDevice={currentDevice}
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				position={position}
			/>
			<WidgetSettings
				t={t}
				onSave={saveDiff}
				data={data}
				unit={gridspec.unit}
				position={position}
				currentDevice={currentDevice}
				currentRef={currentRef}
				direction={direction}
				alignItems={alignItems}
			>
				{({ activeTab }) =>
					activeTab === 'layout' && (
						<Settings
							data={data}
							onSave={saveDiff}
							currentDevice={currentDevice}
						/>
					)
				}
			</WidgetSettings>
		</>
	);
};

export default React.memo<TConnectPropsWithControls>(Controls);
