// @flow
import React from 'react';
import assign from 'lodash/fp/assign';
import Box from '@graphite/uneon/lib/Box';
import { getDesignSx } from '@graphite/selectors';
import type { TDesign } from '@graphite/types';

import type { TProps } from './types';

const baseSx = {
	flexGrow: 1,
};

const Rectangle = (props: TProps, ref) => {
	const {
		data,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
		isPassive = false,
		onClick,
	} = props;

	const { link } = data;

	const designSx = React.useMemo(() => {
		if (!data.designId || !widgetspec || !gridspec || !colorspec || !effectspec) {
			return null;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.rectangle?.find((d) => d._id === data.designId);
		if (!design) {
			return null;
		}

		return getDesignSx({
			design,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
		});
	}, [data, widgetspec, gridspec, colorspec, effectspec]);

	const linkProps = React.useMemo(() => {
		if (isPassive || !(link && link.href)) {
			return { as: 'div' };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : 'self',
		};
	}, [isPassive, link]);

	const sx = React.useMemo(() => assign(baseSx, designSx), [designSx]);

	return (
		<Box
			id={data.id}
			className={data.className}
			ref={ref}
			sx={sx}
			onClick={onClick}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...linkProps}
		/>
	);
};

export default React.memo<TProps>(React.forwardRef(Rectangle));
