// @flow
import React from 'react';
import assign from 'lodash/fp/assign';
import emptyObject from 'empty/object';
import Box from '@graphite/uneon/lib/Box';
import { getDesignSx } from '@graphite/selectors';
import type { TSx, TDesign } from '@graphite/types';

import type { TWrapProps } from './types';

const baseSx = {
	flexGrow: 1,
	overflow: 'hidden',
	alignItems: 'center',
	justifyContent: 'center',
};

const Wrap = (props: TWrapProps) => {
	const {
		children,
		data,
		colorspec,
		widgetspec,
		effectspec,
		gridspec,
		isPassive = false,
		onClick,
		onMouseEnter,
		onMouseLeave,
	} = props;

	const { link } = data;

	const designSx: TSx = React.useMemo(() => {
		if (!data.designId || !widgetspec || !colorspec || !effectspec || !gridspec) {
			return emptyObject;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			(custom && custom.target === 'image' ? custom : null) ||
			widgetspec?.image.find((d) => d._id === data.designId);

		if (!design) {
			return emptyObject;
		}

		return getDesignSx({
			design,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
		});
	}, [data.designId, data.designs, widgetspec, colorspec, effectspec, gridspec]);

	const sx: TSx = React.useMemo(() => assign(baseSx, designSx), [designSx]);

	const linkProps = React.useMemo(() => {
		if (isPassive || !(link && link.href)) {
			return { as: 'div' };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : 'self',
		};
	}, [isPassive, link]);

	return (
		<Box
			id={data.id}
			className={data.className}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			sx={sx}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...linkProps}
		>
			{children}
		</Box>
	);
};

export default React.memo<TWrapProps>(Wrap);
