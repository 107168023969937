// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Box, Text, Logo } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	email: string,
	logOut: () => void,
	insertProject: () => void,
|}>;

const topBarSx = {
	justifyContent: 'space-between',
	alignItems: 'center',
	flexDirection: ['column', 'row'],
	paddingTop: '15px',
	paddingBottom: '14px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
};

const logoSx = {
	width: '120px',
};

const menuBarSx = {
	flexDirection: ['column', 'row'],
	justifyContent: 'space-between',
	alignItems: ['start', 'center'],
	paddingTop: '35px',
	paddingBottom: '57px',
	h2: {
		marginRight: '30px',
	},
};

const RightButtons = styled(Flex)`
	gap: 24px;
	max-width: 100%;
	white-space: nowrap;
`;

const TextName = styled.span`
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Header = ({ logOut, insertProject, email }: TProps) => {
	const { t } = useTranslation();

	return (
		<Box data-kind="user-header" variant="container.fluid">
			<Box variant="container.negative">
				<Flex sx={topBarSx} variant="container.fluid">
					<Link data-kind="user-header-logo" to="/">
						<Logo sx={logoSx} />
					</Link>
					<RightButtons>
						<Button
							data-kind="user-header-email"
							variant="primaryaltflat.flat.sm"
							disabled
						>
							<TextName>{email}</TextName>
						</Button>
						<Button
							data-kind="user-header-help"
							as="a"
							variant="primaryaltflat.flat.sm"
							href="//feedback.graphite.space"
							target="_blank"
							rel="noreferrer"
							alt={t('Help')}
						>
							{t('Help')}
						</Button>
						<Button
							data-kind="user-logout"
							variant="primaryaltflat.flat.sm"
							onClick={logOut}
						>
							{t('dashboard.header.logout')}
						</Button>
					</RightButtons>
				</Flex>
			</Box>

			<Flex sx={menuBarSx}>
				<Text as="h2" variant="title1" color="text.primaryalt">
					{t('dashboard.header.projects')}
				</Text>
				<Button
					data-kind="user-header-insert-proect"
					variant="accentflat.flat.sm"
					onClick={insertProject}
				>
					{t('dashboard.header.new project')}
				</Button>
			</Flex>
		</Box>
	);
};
export default React.memo<TProps>(Header);
