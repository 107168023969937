// @flow
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { Box, Flex, Text, Logo, Link, themes } from '@graphite/uneon';
import { config } from '@graphite/constants';
import logger from '@graphite/logger';
import InputAuth from '../InputAuth';
import ButtonAuth from '../ButtonAuth';
import FormGroupAuth from '../FormGroupAuth';
import figureImage from './figure.inline.svg';

// FixMe переделать mouse на synthetic event, потому что типы ругаются
type TProps = $ReadOnly<{|
	signInWithGoogle: (e: MouseEvent) => void,
	createUserWithEmailAndPassword: ({
		email: string,
		password: string,
		name: string,
	}) => void,
	isAuth: boolean,
	error: ?{
		code: string,
		message: string,
	},
|}>;

const bodySx = {
	minHeight: '100vh',
	backgroundColor: 'bg.primary',
	alignItems: 'stretch',
};

const sidebarWrapperSx = {
	display: ['none', 'block'],
	minWidth: '300px',
};

const sidebarWrapperWidth = 1 / 5;

const sidebarSx = {
	height: '100%',

	position: 'relative',
	flexDirection: 'column',
	justifyContent: 'center',

	backgroundColor: 'bg.primaryminus',
	backgroundImage: `url(${figureImage})`,
	backgroundPosition: 'bottom -350px right -430px;',
	backgroundRepeat: 'no-repeat',
};

const sidebarGradientSx = {
	position: 'absolute',
	bottom: '0',
	left: '0',
	right: '0',
	width: '100%',
	height: '526px',
	backgroundImage: 'linear-gradient(0deg, #020203 2.8%, rgba(2, 2, 3, 0) 100%)',
};

const logoSidebarSx = {
	position: 'absolute',
	top: '30px',
};

const sidebarTitleSx = {
	position: 'relative',
	h2: {
		color: 'text.primaryalt',
		marginBottom: '12px',
	},
	h5: {
		color: 'text.primary',
	},
};

const contentSx = {
	position: 'relative',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: ['start', 'center'],
	textAlign: 'center',
};

const contentWidth = [1, 4 / 5];

const logoWrapperSx = {
	display: ['block', 'none'],
	margin: '48px 0 0 0',
};

const logoFormSx = {
	width: '120px',
};

const authFormSx = {
	width: '300px',
	margin: '60px 0',
	h1: {
		marginBottom: '36px',
	},
};

const separatorWrapperSx = {
	marginBottom: '18px',
	height: '21px',
};

const separatorSx = {
	position: 'relative',
	top: '12px',
	left: '0',
	right: '0',
	height: '1px',
	marginBottom: '-1px',
	backgroundColor: 'bg.primaryalt',
};

const separatorTextSx = {
	position: 'relative',
	display: 'inline-block',
	padding: '0 12px',
	backgroundColor: 'bg.primary',
	color: 'text.secondary',
};

const errorSx = {
	color: 'text.error',
	marginBottom: '18px',
	textAlign: 'left',
};

const googleIcon = [
	{
		name: 'social-google',
		iconSize: 24,
		size: 'xsm',
	},
];

const productInfoSx = {
	border: '2px solid',
	borderColor: 'bg.secondary',
	borderRadius: '12px',
	padding: '14px 24px',
	textAlign: 'left',
	marginBottom: '36px',
};

const SignUp = ({
	signInWithGoogle,
	createUserWithEmailAndPassword,
	isAuth,
	error,
}: TProps) => {
	const { t } = useTranslation();
	const history = useHistory();
	const [isChange, setChanged] = React.useState(true);
	const { handleSubmit, register } = useForm();
	// for analytics
	React.useEffect(() => {
		logger.info('signUpPageVisit');
	}, []);

	const signUpWithGoogleHandler = React.useCallback(
		e => {
			signInWithGoogle(e);
			logger.info('signUp', { type: 'google' });
		},
		[signInWithGoogle],
	);

	const loginLinkHandler = React.useCallback(
		e => {
			e.preventDefault();
			history.push('/login');
		},
		[history],
	);

	const handleSubmitForm = React.useCallback(
		e => {
			setChanged(false);
			handleSubmit(createUserWithEmailAndPassword)(e);
		},
		[createUserWithEmailAndPassword, handleSubmit],
	);

	const handleChangeForm = React.useCallback(() => {
		setChanged(true);
	}, []);

	const isInvalidName = !isChange && error && error.code.includes('name');
	const isInvalidEmail = !isChange && error && error.code.includes('email');
	const isInvalidPassword = !isChange && error && error.code.includes('password');

	return (
		<ThemeProvider theme={themes.dark}>
			<Flex sx={bodySx}>
				<Box width={sidebarWrapperWidth} sx={sidebarWrapperSx}>
					<Flex sx={sidebarSx} variant="container.fluid">
						<Box sx={sidebarGradientSx} />
						<a href={config.appHome}>
							<Logo sx={logoSidebarSx} />
						</a>
						<Box sx={sidebarTitleSx}>
							<Text as="h2" variant="hero3">
								{t('auth.signup.sidebar title')}
							</Text>
							<Text as="h5" variant="title4">
								{t('auth.signup.sidebar tagline')}
							</Text>
						</Box>
					</Flex>
				</Box>

				<Flex variant="container.fluid" width={contentWidth} sx={contentSx}>
					<Box sx={logoWrapperSx}>
						<a href={config.appHome}>
							<Logo sx={logoFormSx} />
						</a>
					</Box>
					<Box sx={authFormSx}>
						<Text as="h1" variant="title2" color="text.primaryalt">
							{t('auth.signup.form title')}
						</Text>
						<Text variant="bodymd" color="text.primary" sx={productInfoSx}>
							<Trans i18nKey="auth.signup.product info">
								During the alpha and beta stage we provide{' '}
								<Text as="span" variant="bodymd" color="text.success">
									free access
								</Text>
								to all product features.
							</Trans>
						</Text>
						<ButtonAuth
							type="button"
							name="start"
							size="lg"
							label={t('auth.signup.with google')}
							colors="accent"
							shape="rounded"
							onClick={signUpWithGoogleHandler}
							icons={googleIcon}
						/>

						<Box sx={separatorWrapperSx}>
							<Box sx={separatorSx} />
							<Text variant="bodymd" sx={separatorTextSx}>
								{t('auth.separator')}
							</Text>
						</Box>

						<form onSubmit={handleSubmitForm} onChange={handleChangeForm}>
							<FormGroupAuth>
								<InputAuth
									size="lg"
									name="name"
									className="protected"
									placeholder={t('auth.name')}
									ref={register({
										required: 'Required',
									})}
									disabled={isAuth}
									isError={isInvalidName}
								/>
								{error && isInvalidName && !isChange && (
									<Text variant="bodysm" sx={errorSx}>
										{t(error.message, { keySeparator: false })}
									</Text>
								)}

								<InputAuth
									size="lg"
									name="email"
									className="protected"
									placeholder={t('auth.email')}
									ref={register({
										required: 'Required',
									})}
									disabled={isAuth}
									isError={isInvalidEmail}
								/>
								{error && isInvalidEmail && !isChange && (
									<Text variant="bodysm" sx={errorSx}>
										{t(error.message, { keySeparator: false })}
									</Text>
								)}

								<InputAuth
									name="password"
									className="protected"
									size="lg"
									placeholder={t('auth.password')}
									type="password"
									ref={register({
										required: 'Required',
									})}
									disabled={isAuth}
									isError={isInvalidPassword}
								/>
								{error && isInvalidPassword && !isChange && (
									<Text variant="bodysm" sx={errorSx}>
										{t(error.message, { keySeparator: false })}
									</Text>
								)}
							</FormGroupAuth>

							<ButtonAuth
								type="submit"
								size="lg"
								label={t('auth.signup.create account')}
								colors="accentghost"
								shape="rounded"
								isDisabled={isAuth}
							/>
						</form>
						<Text variant="bodysm" color="text.secondary">
							{t('auth.signup.have account')}
							<Link href="/login" onClick={loginLinkHandler} variant="link">
								{t('auth.login.link')}
							</Link>
						</Text>
					</Box>
				</Flex>
			</Flex>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(SignUp);
