// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import {
	Toolbar,
	ToolbarBtn,
	Popper,
	Tooltip,
	ToolbarDelimitr,
	Text,
} from '@graphite/uneon';
import { closestDeviceWithKey } from '@graphite/selectors';
import type {
	TGridBreakpointName,
	TWidgetBoxBreakpoint,
	TWidgetDiff,
} from '@graphite/types';
import type { TWidgetStack } from '../constants/types';

type TProps = $ReadOnly<{|
	device: TGridBreakpointName,
	data: TWidgetStack,
	save: TWidgetDiff => void,
	openedPanel: ?string,
	setOpenedPanel: (?string) => void,
|}>;

const ICONS = {
	row: 'arrow-right-18',
	column: 'arrow-down-18',
	wrap: 'arrow-zigzag-right-18',
};

const Direction = ({ device, save, data, setOpenedPanel, openedPanel }: TProps) => {
	const { t } = useTranslation();

	const rowBtnEl: React$ElementRef<any> = React.useRef(null);
	const columnBtnEl: React$ElementRef<any> = React.useRef(null);
	const wrapBtnEl: React$ElementRef<any> = React.useRef(null);
	const directionBtnEl: React$ElementRef<any> = React.useRef(null);

	const handleOpenPanel = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(openedPanel !== 'direction' ? 'direction' : null);
		},
		[openedPanel, setOpenedPanel],
	);

	const box: TWidgetBoxBreakpoint = closestDeviceWithKey(data.box, {
		currentDevice: device,
		key: `box-${data._id}`,
	});

	const {
		flexDirection: direction = 'column',
		flexWrap,
		alignItems = 'flex-start',
	} = box;

	const isWrap = flexWrap === 'wrap';

	const saveDirection = React.useCallback(
		value =>
			save(
				_.update(
					`box.${device}`,
					val => ({
							...val,
							alignItems:
								value !== 'row' && alignItems === 'baseline'
									? 'flex-start'
									: alignItems,
							flexDirection: value,
						}),
					data,
				),
			),
		[save, device, data, alignItems],
	);

	const setRow = React.useCallback(() => saveDirection('row'), [saveDirection]);
	const setColumn = React.useCallback(() => saveDirection('column'), [saveDirection]);
	const setWrap = React.useCallback(
		() => save(_.set(`box.${device}.flexWrap`, isWrap ? 'nowrap' : 'wrap', data)),
		[data, device, save, isWrap],
	);
	const isOpenedPanel = openedPanel === 'direction';

	return (
		<>
			<ToolbarBtn
				onClick={handleOpenPanel}
				iconName={ICONS[direction]}
				ref={directionBtnEl}
				isActive={isOpenedPanel}
			/>
			<Tooltip elRef={directionBtnEl} placement={isOpenedPanel ? 'bottom' : 'top'}>
				{t('Flow')}&nbsp;&nbsp;
				<Text as="span" color="spec.lightblue80">
					{direction === 'row' ? t('Row') : t('Column')}
				</Text>
			</Tooltip>

			<Popper
				isOpen={isOpenedPanel}
				anchorEl={directionBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<ToolbarBtn
						onClick={setRow}
						iconName={ICONS.row}
						ref={rowBtnEl}
						isActive={direction === 'row'}
					/>
					<Tooltip elRef={rowBtnEl} placement="top">
						{t('Row')}
					</Tooltip>
					<ToolbarBtn
						onClick={setColumn}
						iconName={ICONS.column}
						ref={columnBtnEl}
						isActive={direction === 'column'}
					/>
					<Tooltip elRef={columnBtnEl} placement="top">
						{t('Column')}
					</Tooltip>

					<ToolbarDelimitr />

					<ToolbarBtn
						onClick={setWrap}
						iconName={ICONS.wrap}
						ref={wrapBtnEl}
						isActive={isWrap}
					/>

					<Tooltip elRef={wrapBtnEl} placement="top">
						{t('Wrap')}
					</Tooltip>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(Direction);
