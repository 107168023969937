// @flow
import React from 'react';
import { getTrueIds, getWidget } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import logger from '@graphite/logger';

import { placeWidget, editWidget } from 'Editor/ducks/widgets';
import { logOut } from 'Editor/ducks/users';
import type { TWidgetOwnProps, TWidget } from '@graphite/types';

import User from './User';

const UserEdit = ({ id }: TWidgetOwnProps) => {
	const dispatch = useDispatch();

	React.useEffect(() => {
		logger.info('dashboardVisit');
	}, []);

	const user: ?TWidget = useSelector(state => getWidget(state, { id }));
	const users = useSelector(state => state.users);
	const isShowFormAboutUser =
		!user?.profession || !user?.employment || !user?.employees;

	const onInsert = React.useCallback(() => {
		dispatch(
			placeWidget('project', id, null, id, {
				kind: 'grid',
				prevId: null,
				nextId: null,
				destRect: null,
				dragRect: null,
				breakpoints: null,
			}),
		);
	}, [dispatch, id]);

	// eslint-disable-next-line no-void
	const logOutHandler = React.useCallback(() => void dispatch(logOut()), [dispatch]);

	const saveInfo = React.useCallback(
		info => {
			logger.info('aboutUser', info, info);

			dispatch(
				editWidget(id, null, id, {
					...info,
				}),
			);
		},
		[dispatch, id],
	);

	const babies = user
		? getTrueIds({
				...user,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];

	return (
		<User
			id={id}
			email={users?.name || user?.name || users?.email || user?.email}
			isShowFormAboutUser={isShowFormAboutUser}
			babies={babies}
			onInsert={onInsert}
			logOut={logOutHandler}
			saveInfo={saveInfo}
		/>
	);
};

export default React.memo<TWidgetOwnProps>(UserEdit);
