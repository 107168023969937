// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'emotion-theming';
import useHotkeys from '@graphite/use-hotkeys';
import { Box, Flex, Text, Logo, SelectSimple, Button, themes } from '@graphite/uneon';
import { config } from '@graphite/constants';
import logger from '@graphite/logger';
import figureImage from 'SignUp/figure.inline.svg';

type TProps = $ReadOnly<{
	saveInfo: (
		$ReadOnly<{
			profession: string,
			employment: string,
			employees: string,
		}>,
	) => void,
}>;

const bodySx = {
	position: 'fixed',
	minHeight: '100vh',
	width: '100vw',
	backgroundColor: 'bg.primary',
	alignItems: 'stretch',
	zIndex: 100,
};

const sidebarWrapperSx = {
	display: ['none', 'block'],
	minWidth: '300px',
};

const sidebarWrapperWidth = 1 / 5;

const sidebarSx = {
	height: '100%',

	position: 'relative',
	flexDirection: 'column',
	justifyContent: 'center',

	backgroundColor: 'bg.primaryminus',
	backgroundImage: `url(${figureImage})`,
	backgroundPosition: 'bottom -350px right -430px;',
	backgroundRepeat: 'no-repeat',
};

const sidebarGradientSx = {
	position: 'absolute',
	bottom: '0',
	left: '0',
	right: '0',
	width: '100%',
	height: '526px',
	backgroundImage: 'linear-gradient(0deg, #020203 2.8%, rgba(2, 2, 3, 0) 100%)',
};

const logoSidebarSx = {
	position: 'absolute',
	top: '30px',
};

const sidebarTitleSx = {
	position: 'relative',
	h2: {
		color: 'text.primaryalt',
		marginBottom: '12px',
	},
	h5: {
		color: 'text.primary',
	},
};

const contentSx = {
	position: 'relative',
	alignItems: 'center',
	justifyContent: 'center',
};

const contentWidth = [1, 4 / 5];

const logoWrapperSx = {
	display: ['block', 'none'],
	margin: '48px 0 0 0',
};

const logoFormSx = {
	width: '120px',
};

const authFormSx = {
	margin: '60px 0',
	h1: {
		marginBottom: '36px',
	},
};

const titleSx = {
	marginBottom: '18px',
};

const infoSx = {
	marginBottom: '36px',
	whiteSpace: 'pre-wrap',
};

const buttonSx = {
	justifyContent: 'start',
};

const selectSx = {
	marginBottom: '30px',
};

const Info = ({ saveInfo }: TProps) => {
	const { t } = useTranslation();

	// Я был вынужден написать так, ибо у нас нет селекта который нормально работает
	const [aboutUser, changeAboutUser] = React.useState({
		profession: 'none',
		employment: 'none',
		employees: 'none',
	});
	const [selectStatus, checkStatus] = React.useState('none');

	// for analytics
	React.useEffect(() => {
		logger.info('formAboutYourselfPageVisit');
	}, []);

	const professionGroup = React.useMemo(
		() =>
			({
				items: [
					{
						name: 'none',
						label: t('auth.about.profession.placeholder'),
					},
					{
						name: 'ux ui designer',
						label: t('auth.about.profession.ux ui designer'),
					},
					{
						name: 'web designer',
						label: t('auth.about.profession.web designer'),
					},
					{
						name: 'product designer',
						label: t('auth.about.profession.product designer'),
					},
					{
						name: 'creative lead art director',
						label: t('auth.about.profession.creative lead art director'),
					},
					{ name: 'developer', label: t('auth.about.profession.developer') },
					{ name: 'other', label: t('auth.about.profession.other') },
				],
				active: aboutUser.profession,
			} || null),
		[t, aboutUser.profession],
	);

	const employmentGroup = React.useMemo(
		() =>
			({
				items: [
					{
						name: 'none',
						label: t('auth.about.employment.placeholder'),
					},
					{
						name: 'self-employed',
						label: t('auth.about.employment.self-employed'),
					},
					{
						name: 'work in a company',
						label: t('auth.about.employment.work in a company'),
					},
					{ name: 'other', label: t('auth.about.employment.other') },
				],
				active: aboutUser.employment,
			} || null),
		[t, aboutUser.employment],
	);

	const peopleGroup = React.useMemo(
		() =>
			({
				items: [
					{
						name: 'none',
						label: t('auth.about.people.placeholder'),
					},
					{ name: '1', label: t('auth.about.people.1') },
					{ name: '2-5', label: t('auth.about.people.2-5') },
					{ name: '6-15', label: t('auth.about.people.6-15') },
					{ name: '15 and more', label: t('auth.about.people.15 and more') },
				],
				active: aboutUser.employees,
			} || null),
		[t, aboutUser.employees],
	);

	const changeProfessionHandle = React.useCallback(
		(e, name) => {
			if (typeof name !== 'string') {
				return;
			}

			changeAboutUser({
				...aboutUser,
				profession: name,
			});
		},
		[aboutUser],
	);

	const changeEmploymentHandle = React.useCallback(
		(e, name) => {
			if (typeof name !== 'string') {
				return;
			}

			changeAboutUser({
				...aboutUser,
				employment: name,
			});
		},
		[aboutUser],
	);

	const changePeopleHandle = React.useCallback(
		(e, name) => {
			if (typeof name !== 'string') {
				return;
			}

			changeAboutUser({
				...aboutUser,
				employees: name,
			});
		},
		[aboutUser],
	);

	const submitHandler = React.useCallback(() => {
		if (
			aboutUser.profession === 'none' ||
			aboutUser.employment === 'none' ||
			aboutUser.employees === 'none'
		) {
			checkStatus('error');
			return;
		}

		saveInfo(aboutUser);
	}, [saveInfo, aboutUser]);

	const hotkeyHandler = React.useCallback(
		(e) => {
			e.preventDefault();
			saveInfo({
				profession: 'QA engineer',
				employment: 'Other',
				employees: '1',
			});
		},
		[saveInfo],
	);

	useHotkeys('Control+Shift+q', hotkeyHandler);

	return (
		<ThemeProvider theme={themes.dark}>
			<Flex data-kind="user-about" sx={bodySx}>
				<Box width={sidebarWrapperWidth} sx={sidebarWrapperSx}>
					<Flex sx={sidebarSx} variant="container.fluid">
						<Box sx={sidebarGradientSx} />
						<a href={config.appHome}>
							<Logo sx={logoSidebarSx} />
						</a>
						<Box sx={sidebarTitleSx}>
							<Text as="h2" variant="hero3">
								{t('auth.signup.sidebar title')}
							</Text>
							<Text as="h5" variant="title4">
								{t('auth.signup.sidebar tagline')}
							</Text>
						</Box>
					</Flex>
				</Box>

				<Flex variant="container.fluid" width={contentWidth} sx={contentSx}>
					<Box sx={logoWrapperSx}>
						<a href={config.appHome}>
							<Logo sx={logoFormSx} />
						</a>
					</Box>
					<Box sx={authFormSx}>
						<Text variant="title2" color="text.primaryalt" sx={titleSx}>
							{t('auth.about.one more thing')}
						</Text>
						<Text variant="bodymd" color="text.primary" sx={infoSx}>
							{t('auth.about.info text')}
						</Text>

						<Box data-kind="user-about-profession" sx={selectSx}>
							<Text variant="headlinemd" color="text.primaryalt">
								{t('auth.about.profession.label')}
							</Text>
							<SelectSimple
								name="profession"
								size="lg"
								list={professionGroup}
								onClick={changeProfessionHandle}
								buttonSx={buttonSx}
								colors="primary"
							/>
						</Box>

						<Box data-kind="user-about-employment" sx={selectSx}>
							<Text variant="headlinemd" color="text.primaryalt">
								{t('auth.about.employment.label')}
							</Text>
							<SelectSimple
								size="lg"
								list={employmentGroup}
								onClick={changeEmploymentHandle}
								buttonSx={buttonSx}
								colors="primary"
							/>
						</Box>

						<Box data-kind="user-about-people" sx={selectSx}>
							<Text variant="headlinemd" color="text.primaryalt">
								{t('auth.about.people.label')}
							</Text>
							<SelectSimple
								size="lg"
								list={peopleGroup}
								onClick={changePeopleHandle}
								buttonSx={buttonSx}
								colors="primary"
							/>
						</Box>
						{selectStatus === 'error' && (
							<Text variant="headlinemd" color="text.error" sx={titleSx}>
								{t('auth.about.error')}
							</Text>
						)}
						<Button
							data-kind="user-about-submit"
							variant="accentghost.rounded.lg"
							onClick={submitHandler}
						>
							{t('auth.about.submit')}
						</Button>
					</Box>
				</Flex>
			</Flex>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Info);
